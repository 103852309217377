export default {
  loadingConfirmacao: false,
  emprestimoSolicitado: false,
  etapaAtualObj: {
    id: '',
    label: '',
    container: null,
    exibirSteper: false,
  },
  parametrizacao: {},
  pessoaBigdatacorpSincronizadaNoFluxo: false,
  pessoa: {
    documento: '',
    dataNascimento: null,
    genero: '',
    email: '',
    emailCorporativo: '',
    celular: '',
    nome: '',
    contatosConfirmacaos: [],
    profissaoId: '',
    estadoCivil: '',
    nomeMae: '',
    possuiSenhaFirebase: false,
    rg: '',
    emissorRg: '',
    ufEmissorRg: '',
    dataEmissaoRg: null,
    dataAtualizacaoBigdatacorp: null,
    cargo: '',
    ufNaturalidade: '',
    cidadeNaturalidade: '',
  },
  empresa: {},
  dadosConfirmados: { email: false, celular: false },
  ofertaEmprestimo: {},
  token: undefined,
  idToken: undefined,
  taxaJuros: undefined,
  dataExpiracao: undefined,
  simulacaoValues: undefined,
  preCadastroValues: undefined,
  loading: false,
  persistido: false,
  logout: false,
  msg: {},
  prontoParaPersistir: false,
  tokenData: undefined,
  organizacao: {},
  resumeStatus: {},
  confirmacaoContato: {
    modal: { open: false },
  },
  contaBancaria: {
    banco: {
      id: '',
      nome: '',
    },
    agencia: '',
    conta: '',
    tipoConta: 'CORRENTE',
  },
  dadosDocumentos: {
    bucketPath: '',
    docFrenteCapturaAutomatica: false,
    docVersoCapturaAutomatica: false,
    tipoDocumento: undefined,
  },
  origem: 'SITE',
};
