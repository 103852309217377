import React, { useRef } from 'react';

import FooterInstitucional from 'components/FooterInstitucional';
import { useAppGlobal } from 'providers/AppGlobal';

import { CircularProgress } from '@material-ui/core';

import SectionApresentacao from '../../components/Institucional/SectionApresentacao';
import SectionGraficos from '../../components/Institucional/SectionGraficos';
import SectionOqueFazemos from '../../components/Institucional/SectionOqueFazemos';
import SectionQuemSomos from '../../components/Institucional/SectionQuemSomos';
import InstitucionalScrollButton from '../../components/InstitucionalScrollButton';

export default function InstitucionalContainer({ dadosSelic, taxas }) {
  const sectionsRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    // useRef()
  ];
  const footerRef = useRef();

  const {
    tema: { loaded },
    actions: { getIcone },
  } = useAppGlobal();

  return !loaded ? (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <SectionApresentacao getIcone={getIcone} sectionRef={sectionsRefs[0]} />
      <SectionQuemSomos sectionRef={sectionsRefs[1]} />
      <SectionOqueFazemos sectionRef={sectionsRefs[2]} getIcone={getIcone} />
      <SectionGraficos
        sectionRef={sectionsRefs[3]}
        dadosSelic={dadosSelic}
        taxas={taxas}
      />
      {/* <SectionEmpresasConectadas */}
      {/*  sectionRef={sectionsRefs[4]} */}
      {/*  nomeOrganizacao={nomeOrganizacao} */}
      {/* /> */}
      <FooterInstitucional Ref={footerRef} />
      <InstitucionalScrollButton
        sectionsRefs={sectionsRefs}
        ocultarBotaoSobreRef={footerRef}
      />
    </>
  );
}
